@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@0,400;0,700;1,400;1,700&display=swap");
.grid-layout::-webkit-scrollbar {
  display: none;
}

.destination-nav::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
  border-radius: 12px;
}

/* background of the scrollbar except button or resizer */
.destination-nav::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 12px;
}

/* scrollbar itself */
.destination-nav::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 6px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.destination-nav::-webkit-scrollbar-button {
  display: none;
  border-radius: 12px;
}
.date_picker::placeholder {
  color: #1a093f;
}
.textInput::placeholder {
  color: #1a093f;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
/* .slick-track {
  width: 100% !important;
} */
.hidden-scroll::-webkit-scrollbar {
  width: 0px;
}

@media only screen and (min-width: 500px) {
  .details-slick .slick-track {
    width: auto !important;
  }
}
